<template>
  <b-modal
    :id="id"
    size="md"
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
    no-close-on-backdrop
  >
    <template #modal-header="{ cancel }">
      <div slot="modal-title" class="modal-title">
        <b-icon :icon="taskTypeIcon"></b-icon>
        <span>{{ selectInfo.title }}</span>
      </div>
      <button
        type="button"
        class="close"
        title="Chiudi"
        data-dismiss="modal"
        @click="cancel()"
      >
        &times;
      </button>
    </template>

    <template #modal-footer="{ cancel }">
      <b-form-group>
        <b-btn @click="cancel()" size="sm" variant="outline-secondary"
          >CHIUDI</b-btn
        >
        <b-btn
          v-if="hasRelation"
          @click="
            cancel();
            link();
          "
          :title="'Vai a ' + selectInfo.extendedProps.taskable"
          size="sm"
          variant="outline-primary"
          >VAI</b-btn
        >
        <b-btn
          @click="
            cancel();
            changeEvent();
          "
          size="sm"
          variant="outline-lisaweb"
          >MODIFICA</b-btn
        >
        <b-btn
          @click="
            cancel();
            deleteEvent();
          "
          size="sm"
          variant="outline-danger"
          >ELIMINA</b-btn
        >
      </b-form-group>
    </template>
    <b-card>
      <b-card-text v-if="selectInfo">
        <div v-if="hasTime">
          <strong>Orario</strong>
          <div>
            <ul>
              <li v-if="selectInfo.allDay === false && selectInfo.start">
                inizio:
                {{ `${moment(selectInfo.start).format("HH:mm")}` }}
              </li>
              <li v-if="selectInfo.allDay === false && selectInfo.end">
                fine:
                {{ `${moment(selectInfo.end).format("HH:mm")}` }}
              </li>
              <!-- <li v-if="selectInfo.allDay === true">all-day</li> -->
            </ul>
          </div>
        </div>
        <div v-else-if="isAllDay">
          <strong>Tutto il giorno</strong>
        </div>
        <div
          v-if="
            isAllDay ||
            (hasDate && selectInfo.start && !selectInfo.end) ||
            (selectInfo.start &&
              selectInfo.end &&
              moment(selectInfo.start).format('DD/MM/YYYY') ===
                moment(selectInfo.end).format('DD/MM/YYYY'))
          "
        >
          <strong>Giorno</strong>
          <ul>
            <li v-if="selectInfo.start">
              il:
              {{ `${moment(selectInfo.start).format("DD/MM/YYYY")}` }}
            </li>
          </ul>
        </div>
        <div v-else-if="hasDate && selectInfo.start && selectInfo.end">
          <strong>Giorni</strong>
          <ul>
            <li v-if="selectInfo.start">
              da:
              {{ `${moment(selectInfo.start).format("DD/MM/YYYY")}` }}
            </li>
            <li v-if="selectInfo.end">
              a:
              {{ `${moment(selectInfo.end).format("DD/MM/YYYY")}` }}
            </li>
          </ul>
        </div>
        <div v-if="selectInfo.extendedProps">
          <div
            v-for="(value, key, index) in selectInfo.extendedProps"
            :key="index"
          >
            <div
              v-for="(v, k, i) in extendedProp(key, value)"
              :key="i"
              v-html="v"
              :class="{ link: key === 'taskable' && value && k === 'text' }"
              :title="
                key === 'taskable' && value && k === 'text'
                  ? `Vai ${
                      value === 'Anagrafiche'
                        ? 'all\'anagrafica'
                        : value === 'Polizze'
                        ? 'alla polizza'
                        : value === 'Sinistri'
                        ? 'al sinistro'
                        : value === 'Contatti di Agenzia'
                        ? 'al contatto'
                        : value === 'Titoli'
                        ? 'al titolo'
                        : value === 'Registrazione a Foglio Cassa'
                        ? 'alla registrazione a foglio cassa'
                        : value
                    }`
                  : ''
              "
              @click="key === 'taskable' && value && k === 'text' && link()"
            />
            <!-- <div v-html="extendedProp(key, value).label" />
            <div v-html="extendedProp(key, value).text" :class="(key==='taskable' && value) ? 'link': ''" @click="key==='taskable' && value && link()"/>
             -->
          </div>
        </div>
      </b-card-text>
    </b-card>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
moment.locale("it");

export default {
  data() {
    return {
      extendedPropsLabels: {
        description: "Descrizione",
        is_public: "Pubblico",
        users: "Utenti",
        owner_id: "Creatore",
        taskable_id: "ID relazione",
        taskable: "Relazione con",
        status_task: "Stato",
        task_type: "Tipo",
        expired_at: "In scadenza",
      },
      excludedProps: [
        "owner_id",
        "created_at",
        "updated_at",
        "deleted_at",
        "last_update",
        "taskable_id",
        "link",
      ],
    };
  },
  props: {
    id: {
      type: String,
      default: "eventShowModal",
    },
    // taskType: {
    //   type: Number,
    //   default: 0,
    // },
    selectInfo: null,
  },
  computed: {
    taskType() {
      console.debug(
        "eventShow taskType(): ",
        this.selectInfo.task_type ? this.selectInfo.task_type.value : "unset"
      );
      return this.selectInfo.task_type ? this.selectInfo.task_type.value : "";
    },
    taskTypeIcon() {
      let icon;
      switch (this.taskType) {
        case 0:
          icon = "calendar2-event";
          break;
        case 1:
          icon = "paperclip";
          break;
        case 2:
          icon = "journal-minus";
          break;
        default:
          icon = "question-circle";
      }
      return icon;
    },
    isAllDay() {
      return this.selectInfo.allDay;
    },
    hasTime() {
      // fromCalendar && selectInfo.allDay !== undefined
      return [0].includes(this.taskType) && !this.isAllDay;
    },
    hasDate() {
      // fromCalendar && selectInfo.allDay !== undefined
      return [0].includes(this.taskType);
    },
    hasRelation() {
      return this.selectInfo.extendedProps.taskable_id;
    },
    fromCalendar() {
      return this.taskType === 0 ? true : false;
    },
    getExtendedPropsLabel() {
      return (prop) => this.extendedPropsLabels[prop] || prop;
    },
    getExtendedPropsValue() {
      return (key, value) => this.getText(key, value);
    },
  },
  // mounted() {
  //   this.$refs['myRelation'].firstChild.addEventListener('click', function(event) {
  //     event.preventDefault();
  //     console.log('clicked: ', event.target);
  //   })
  // },
  methods: {
    moment,
    ...mapGetters("auth", {
      getUsers: "users",
    }),
    // extendedProp(key, value) {
    //   let ret = "";
    //   if (!this.excludedProps.includes(key)) {
    //     if (
    //       value &&
    //       ((Array.isArray(value) && value.length) ||
    //         (!Array.isArray(value) &&
    //           typeof value != "object" &&
    //           (value === 0 || value)) ||
    //         (!Array.isArray(value) &&
    //           typeof value == "object" &&
    //           (value.value === 0 || value.value)))
    //     ) {
    //       ret = `<strong>${this.getExtendedPropsLabel(key)}</strong>
    //       <div class="my-2">${this.getText(key, value)}</div>`;
    //     }
    //   }
    //   return ret;
    // },
    extendedProp(key, value) {
      let ret = { label: "", text: "" };
      if (!this.excludedProps.includes(key)) {
        if (
          value &&
          ((Array.isArray(value) && value.length) ||
            (!Array.isArray(value) &&
              typeof value != "object" &&
              (value === 0 || value)) ||
            (!Array.isArray(value) &&
              typeof value == "object" &&
              (value.value === 0 || value.value)))
        ) {
          ret.label = `<strong>${this.getExtendedPropsLabel(key)}</strong>`;
          ret.text = `<div class="my-2">${this.getText(key, value)}</div>`;
        }
      }
      return ret;
    },
    getText(key, value) {
      let ret = "";
      let data;
      let registriesHtml;
      let policyNumber;
      let claimNumber;
      let agencyContact;
      let formattedTitleCode;
      let bookEntryTitle;
      switch (key) {
        case "users":
          data = this.selectInfo.extendedProps[key]
            ? this.getUsers()
                .filter((user) =>
                  this.selectInfo.extendedProps[key]
                    .map((el) => el.id)
                    .includes(user.value)
                )
                .map((user) => `<li>${user.text}</li>`)
            : [];
          ret = `<ul>${data.join("")}</ul>`;
          break;
        case "is_public":
        case "status_task":
        case "task_type":
          ret = value.text;
          break;
        case "expired_at":
          ret = moment(value).format("DD/MM/YYYY");
          break;
        case "taskable":
          if (value === "Anagrafiche") {
            registriesHtml = this.selectInfo.registries.map((registry) => {
              return `<li>${registry.title}</li>`;
            });
            ret = `<ul>${registriesHtml.join("")}</ul>`;
            break;
          }

          if (value === "Polizze") {
            policyNumber = this.selectInfo.insurance_policies.map((policy) => {
              return `<li>${policy.number}</li>`;
            });
            ret = `<ul>${policyNumber.join("")}</ul>`;
            break;
          }

          if (value === "Sinistri") {
            claimNumber = this.selectInfo.claims.map((claim) => {
              return `<li>${claim.number}</li>`;
            });
            ret = `<ul>${claimNumber.join("")}</ul>`;
            break;
          }

          if (value === "Contatti di Agenzia") {
            agencyContact = this.selectInfo.agency_contacts.map((contact) => {
              return `<li>${contact.formatted_title}</li>`;
            });
            ret = `<ul>${agencyContact.join("")}</ul>`;
            break;
          }

          if (value === "Titoli") {
            formattedTitleCode = this.selectInfo.insurance_ancillaries.map(
              (title) => {
                return `<li>${title.formatted_title}</li>`;
              }
            );
            ret = `<ul>${formattedTitleCode.join("")}</ul>`;
            break;
          }

          if (value === "Registrazione a Foglio Cassa") {
            bookEntryTitle = this.selectInfo.book_entries.map((bookEntry) => {
              return `<li>${bookEntry.formatted_title}</li>`;
            });
            ret = `<ul>${bookEntryTitle.join("")}</ul>`;
            break;
          }

          ret = value;
          break;
        default:
          ret = value;
      }
      return ret;
    },
    changeEvent() {
      this.$emit("change");
    },
    deleteEvent() {
      this.$emit("delete", this.selectInfo);
    },
    link() {
      this.selectInfo.extendedProps.link();
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.link) {
  cursor: pointer;
  color: blue;
}
</style>
