var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: _vm.id,
        size: "md",
        "ok-only": "",
        "ok-title": "Chiudi",
        "ok-variant": "lisaweb",
        "header-bg-variant": "lisaweb",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function ({ cancel }) {
            return [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { slot: "modal-title" },
                  slot: "modal-title",
                },
                [
                  _c("b-icon", { attrs: { icon: _vm.taskTypeIcon } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Crea " + _vm._s(_vm.taskTypeLabel))]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    title: "Chiudi",
                    "data-dismiss": "modal",
                  },
                  on: {
                    click: function ($event) {
                      cancel()
                      _vm.cancelEventCreation()
                    },
                  },
                },
                [_vm._v("\n      ×\n    ")]
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("validation-observer", {
        ref: "observer2",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ handleSubmit, invalid }) {
              return [
                _c(
                  "b-card",
                  [
                    _c(
                      "b-card-text",
                      [
                        _c("base-input", {
                          attrs: {
                            vid: "title",
                            name: _vm.beFormCreate.title.label,
                            label: _vm.beFormCreate.title.label,
                            placeholder: _vm.beFormCreate.title.label,
                            rules: _vm.getRules("title", "task"),
                            maxlength: "25",
                          },
                          model: {
                            value: _vm.inputVal.title,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputVal, "title", $$v)
                            },
                            expression: "inputVal.title",
                          },
                        }),
                        _vm._v(" "),
                        _c("base-textarea", {
                          attrs: {
                            vid: "description",
                            name: _vm.beFormCreate.description.label,
                            label: _vm.beFormCreate.description.label,
                            rules: _vm.getRules("description", "task"),
                            placeholder: _vm.beFormCreate.description.label,
                          },
                          model: {
                            value: _vm.inputVal.description,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputVal, "description", $$v)
                            },
                            expression: "inputVal.description",
                          },
                        }),
                        _vm._v(" "),
                        _vm.fromCalendar
                          ? _c(
                              "b-button-group",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle:collapse-2",
                                    arg: "collapse-2",
                                  },
                                ],
                                staticClass: "my-2 filter-button-group",
                              },
                              [
                                _vm._v("Appuntamento\n          "),
                                _c(
                                  "span",
                                  { staticClass: "when-open" },
                                  [
                                    _c("b-icon", {
                                      attrs: { icon: "chevron-up" },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "when-closed" },
                                  [
                                    _c("b-icon", {
                                      attrs: { icon: "chevron-down" },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.fromCalendar
                          ? _c(
                              "b-collapse",
                              {
                                attrs: {
                                  id: "collapse-2",
                                  visible: _vm.fromCalendar,
                                },
                              },
                              [
                                _vm.inputVal.fromDate === _vm.inputVal.toDate
                                  ? _c("b-form-group", {
                                      attrs: { label: "Tutto il giorno" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ ariaDescribedby }) {
                                              return [
                                                _c("base-checkbox", {
                                                  attrs: {
                                                    vid: "allDay",
                                                    name: "allDay",
                                                    "aria-describedby":
                                                      ariaDescribedby,
                                                  },
                                                  model: {
                                                    value: _vm.inputVal.allDay,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.inputVal,
                                                        "allDay",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "inputVal.allDay",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.inputVal.allDay
                                  ? _c("base-timepicker", {
                                      attrs: {
                                        vid: "startTime",
                                        name: "startTime",
                                        label: "Dalle ore",
                                        rules: { required: _vm.taskType == 0 },
                                      },
                                      model: {
                                        value: _vm.inputVal.startTime,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.inputVal,
                                            "startTime",
                                            $$v
                                          )
                                        },
                                        expression: "inputVal.startTime",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.inputVal.allDay
                                  ? _c("base-timepicker", {
                                      attrs: {
                                        vid: "endTime",
                                        name: "endTime",
                                        label: "Alle ore",
                                        rules: { required: _vm.taskType == 0 },
                                      },
                                      model: {
                                        value: _vm.inputVal.endTime,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.inputVal, "endTime", $$v)
                                        },
                                        expression: "inputVal.endTime",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("base-datepicker", {
                                  attrs: {
                                    vid: "started_at",
                                    name: "started_at",
                                    label: _vm.beFormCreate.start.label,
                                    rules: { required: _vm.taskType == 0 },
                                  },
                                  model: {
                                    value: _vm.inputVal.fromDate,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.inputVal, "fromDate", $$v)
                                    },
                                    expression: "inputVal.fromDate",
                                  },
                                }),
                                _vm._v(" "),
                                _c("base-datepicker", {
                                  attrs: {
                                    vid: "ended_at",
                                    name: "ended_at",
                                    label: _vm.beFormCreate.end.label,
                                    rules: { required: _vm.taskType == 0 },
                                  },
                                  model: {
                                    value: _vm.inputVal.toDate,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.inputVal, "toDate", $$v)
                                    },
                                    expression: "inputVal.toDate",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("base-datepicker", {
                          attrs: {
                            vid: "expired_at",
                            name: _vm.beFormCreate.expired_at.label,
                            label: _vm.beFormCreate.expired_at.label,
                            rules: _vm.getRules("expired_at", "task"),
                          },
                          model: {
                            value: _vm.inputVal.expired_at,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputVal, "expired_at", $$v)
                            },
                            expression: "inputVal.expired_at",
                          },
                        }),
                        _vm._v(" "),
                        _c("base-radio", {
                          attrs: {
                            vid: "is_public",
                            name: _vm.beFormCreate.is_public.label,
                            label: _vm.beFormCreate.is_public.label,
                            options: _vm.beFormCreate.is_public.options,
                            rules: _vm.getRules("is_public", "task"),
                          },
                          on: { select: _vm.onChangeIsPublic },
                          model: {
                            value: _vm.inputVal.is_public,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputVal, "is_public", $$v)
                            },
                            expression: "inputVal.is_public",
                          },
                        }),
                        _vm._v(" "),
                        _vm.inputVal.is_public === "N"
                          ? _c("base-select", {
                              attrs: {
                                vid: "users",
                                name: _vm.beFormCreate.users.label,
                                label: _vm.beFormCreate.users.label,
                                options: _vm.usersOptions,
                                multiple: true,
                                taggable: false,
                                closeOnSelect: false,
                                rules: _vm.getRules("users", "task"),
                              },
                              model: {
                                value: _vm.inputVal.users,
                                callback: function ($$v) {
                                  _vm.$set(_vm.inputVal, "users", $$v)
                                },
                                expression: "inputVal.users",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.taskType === 1
                          ? _c("base-select", {
                              attrs: {
                                vid: "status_task",
                                name: _vm.beFormCreate.status_task.label,
                                label: _vm.beFormCreate.status_task.label,
                                options: _vm.beFormCreate.status_task.options,
                                rules: { required: true },
                              },
                              model: {
                                value: _vm.inputVal.status_task,
                                callback: function ($$v) {
                                  _vm.$set(_vm.inputVal, "status_task", $$v)
                                },
                                expression: "inputVal.status_task",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.taskType === 2
                          ? _c("label", [_vm._v("Aggiungi Appuntamento")])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.taskType === 2
                          ? _c("base-checkbox", {
                              attrs: {
                                vid: "create_appointment",
                                name: "create_appointment",
                                label: "",
                              },
                              on: { change: _vm.checkAppointment },
                              model: {
                                value: _vm.create_appointment,
                                callback: function ($$v) {
                                  _vm.create_appointment = $$v
                                },
                                expression: "create_appointment",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { visibility: "hidden" } },
                          [
                            _c("base-label", {
                              attrs: {
                                vid: "task_type",
                                name: "task_type",
                                value: _vm.inputVal.task_type,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        staticClass: "float-right",
                        attrs: {
                          type: "button",
                          disabled: invalid,
                          variant: "outline-lisaweb",
                          size: "sm",
                        },
                        on: {
                          click: function ($event) {
                            return handleSubmit(_vm.createEvent)
                          },
                        },
                      },
                      [_vm._v("\n        Salva\n      ")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }