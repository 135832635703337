var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: _vm.id,
        size: "md",
        "ok-only": "",
        "ok-title": "Chiudi",
        "ok-variant": "lisaweb",
        "header-bg-variant": "lisaweb",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function ({ cancel }) {
            return [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { slot: "modal-title" },
                  slot: "modal-title",
                },
                [
                  _c("b-icon", { attrs: { icon: _vm.taskTypeIcon } }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.selectInfo.title))]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    title: "Chiudi",
                    "data-dismiss": "modal",
                  },
                  on: {
                    click: function ($event) {
                      return cancel()
                    },
                  },
                },
                [_vm._v("\n      ×\n    ")]
              ),
            ]
          },
        },
        {
          key: "modal-footer",
          fn: function ({ cancel }) {
            return [
              _c(
                "b-form-group",
                [
                  _c(
                    "b-btn",
                    {
                      attrs: { size: "sm", variant: "outline-secondary" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("CHIUDI")]
                  ),
                  _vm._v(" "),
                  _vm.hasRelation
                    ? _c(
                        "b-btn",
                        {
                          attrs: {
                            title:
                              "Vai a " + _vm.selectInfo.extendedProps.taskable,
                            size: "sm",
                            variant: "outline-primary",
                          },
                          on: {
                            click: function ($event) {
                              cancel()
                              _vm.link()
                            },
                          },
                        },
                        [_vm._v("VAI")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-btn",
                    {
                      attrs: { size: "sm", variant: "outline-lisaweb" },
                      on: {
                        click: function ($event) {
                          cancel()
                          _vm.changeEvent()
                        },
                      },
                    },
                    [_vm._v("MODIFICA")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-btn",
                    {
                      attrs: { size: "sm", variant: "outline-danger" },
                      on: {
                        click: function ($event) {
                          cancel()
                          _vm.deleteEvent()
                        },
                      },
                    },
                    [_vm._v("ELIMINA")]
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "b-card",
        [
          _vm.selectInfo
            ? _c("b-card-text", [
                _vm.hasTime
                  ? _c("div", [
                      _c("strong", [_vm._v("Orario")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("ul", [
                          _vm.selectInfo.allDay === false &&
                          _vm.selectInfo.start
                            ? _c("li", [
                                _vm._v(
                                  "\n              inizio:\n              " +
                                    _vm._s(
                                      `${_vm
                                        .moment(_vm.selectInfo.start)
                                        .format("HH:mm")}`
                                    ) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.selectInfo.allDay === false && _vm.selectInfo.end
                            ? _c("li", [
                                _vm._v(
                                  "\n              fine:\n              " +
                                    _vm._s(
                                      `${_vm
                                        .moment(_vm.selectInfo.end)
                                        .format("HH:mm")}`
                                    ) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                    ])
                  : _vm.isAllDay
                  ? _c("div", [_c("strong", [_vm._v("Tutto il giorno")])])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isAllDay ||
                (_vm.hasDate && _vm.selectInfo.start && !_vm.selectInfo.end) ||
                (_vm.selectInfo.start &&
                  _vm.selectInfo.end &&
                  _vm.moment(_vm.selectInfo.start).format("DD/MM/YYYY") ===
                    _vm.moment(_vm.selectInfo.end).format("DD/MM/YYYY"))
                  ? _c("div", [
                      _c("strong", [_vm._v("Giorno")]),
                      _vm._v(" "),
                      _c("ul", [
                        _vm.selectInfo.start
                          ? _c("li", [
                              _vm._v(
                                "\n            il:\n            " +
                                  _vm._s(
                                    `${_vm
                                      .moment(_vm.selectInfo.start)
                                      .format("DD/MM/YYYY")}`
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ])
                  : _vm.hasDate && _vm.selectInfo.start && _vm.selectInfo.end
                  ? _c("div", [
                      _c("strong", [_vm._v("Giorni")]),
                      _vm._v(" "),
                      _c("ul", [
                        _vm.selectInfo.start
                          ? _c("li", [
                              _vm._v(
                                "\n            da:\n            " +
                                  _vm._s(
                                    `${_vm
                                      .moment(_vm.selectInfo.start)
                                      .format("DD/MM/YYYY")}`
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.selectInfo.end
                          ? _c("li", [
                              _vm._v(
                                "\n            a:\n            " +
                                  _vm._s(
                                    `${_vm
                                      .moment(_vm.selectInfo.end)
                                      .format("DD/MM/YYYY")}`
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.selectInfo.extendedProps
                  ? _c(
                      "div",
                      _vm._l(
                        _vm.selectInfo.extendedProps,
                        function (value, key, index) {
                          return _c(
                            "div",
                            { key: index },
                            _vm._l(
                              _vm.extendedProp(key, value),
                              function (v, k, i) {
                                return _c("div", {
                                  key: i,
                                  class: {
                                    link:
                                      key === "taskable" &&
                                      value &&
                                      k === "text",
                                  },
                                  attrs: {
                                    title:
                                      key === "taskable" &&
                                      value &&
                                      k === "text"
                                        ? `Vai ${
                                            value === "Anagrafiche"
                                              ? "all'anagrafica"
                                              : value === "Polizze"
                                              ? "alla polizza"
                                              : value === "Sinistri"
                                              ? "al sinistro"
                                              : value === "Contatti di Agenzia"
                                              ? "al contatto"
                                              : value === "Titoli"
                                              ? "al titolo"
                                              : value ===
                                                "Registrazione a Foglio Cassa"
                                              ? "alla registrazione a foglio cassa"
                                              : value
                                          }`
                                        : "",
                                  },
                                  domProps: { innerHTML: _vm._s(v) },
                                  on: {
                                    click: function ($event) {
                                      key === "taskable" &&
                                        value &&
                                        k === "text" &&
                                        _vm.link()
                                    },
                                  },
                                })
                              }
                            ),
                            0
                          )
                        }
                      ),
                      0
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }