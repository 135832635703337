var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-form-input", {
    ref: _vm.name,
    staticClass: "base-label",
    style: _vm.css_style,
    attrs: { vid: _vm.vid, value: _vm.inputVal, readonly: "", plaintext: "" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }